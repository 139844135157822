.product-view {
  width: 300px; /* Adjust the size as needed */
  perspective: 1000px;
  margin: 0 auto;
}

.product-image {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.1s;
  transform: rotateY(0deg); /* Initial rotation angle */
}

/* .product-view:hover .product-image {
  transform: rotateY(150deg); 
} */
