body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

p{
	font-size: 14.5px;
}
.footer-link-box .text-golden{
	line-height: 30px;
}

.img-app-icon{
	width: 110px;
}

@media screen and (max-width: 680px){
	.hide-mob {
		display: none;
	}

	.p-cate-img {
		max-width: 160px;
		max-height: 160px;
		margin: auto;
	}
}

@media screen and (min-width:681px) and (max-width:800px){
	.p-cate-img{
		max-width: 250px;
		max-height: 250px;
		margin: auto;
	}
}
@media screen and (min-width:801px) and (max-width:1200px){
	.p-cate-img{
		max-width: 320px;
		max-height: 320px;
		margin: auto;
	}
}

@media screen and (min-width:1201px) and (max-width:1400px){
	.p-cate-img{
		max-width: 350px;
		max-height: 350px;
		margin: auto;
	}
}

@media screen and (min-width:1401px) {
	.p-cate-img{
		max-width: 400px;
		max-height: 300px;
		margin: auto;
	}
}

.currency_drop{
	background-color: transparent;
	color: #023666;
	border: none;
	margin-top: 12px;
}

.related_product .col-sm-4 .product-card{
 background-color: #f8f8f8;
}

body, html, #page{
	background-color:	#fff;
}



.btn-outline {
    color: #023666!important;
}
.btn-outline, .select-box {
    background: transparent!important;
}

.mt-5px{
	margin-top:5px;
}

.mh-70{
	min-height: 80vh;
}

.fr{
	float: right;
}

.fl{
	float: left;
}

.mlr-15{
	margin-left: 15px;
	margin-right: 15px;
}

.text-description{
	color: #ccc;
}


.ml-10{
	margin-left: 10px;
}

.search_icon{
	position:absolute;
	right:20px;
}

.color-strip{
	height: 20px;
    width: 100%;
    position: absolute;
    background-color: #278390;
    top: 0px;
}

.pt-20{
	padding-top: 20px;
}

.mt-80{
	margin-top:75px;
}

.search-input{
	position: absolute;
    right: 10px;
    
    max-width: 120px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search-header{
  width: 85%; background: none; border: none
}

.offcanvas-backdrop.fade.hide{
  display: none !important;
}

.mt-1{
  margin-top: 1rem !important;
}

.empty-icon{
  margin: auto;
  opacity: 0.4;
}

#map {
  display: inline-block;
  width: 100%;
  height: 70vh;
  background-color: #ccc;
}

.text-left{
  text-align: left !important;
}

.align-items-center{
  align-items: center;
}

.mr-10{
  margin-right: 22px;
  margin-top: 12.5px;
}

.mt-12{
  margin-top: 12.5px;
}

.mt-20{
  margin-top:20px;
}

.color-prime{
  color: #278390;
}


.test_box {
	padding: 0;
}
.test_box .inner {

	display: block;
	margin: 10px;
	overflow: hidden;
}
.test_box .inner .test_click {
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0,0,0,.1);
	color: #fff;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	height: 300px;
	mix-blend-mode: normal;
	text-align: center;
	text-decoration: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}
.test_box .inner .test_click:hover {
	background-color: rgba(0,100,255,.2); 
	mix-blend-mode: hard-light;
}
.flex_this {
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	display: inherit;
	flex-wrap: inherit;
	height: auto;
	margin: 0;
	padding: 10px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	width: 100%;

}

.test_box .inner .test_click:hover .flex_this {
	margin-top: -5px;
}

.test_title {
	display: block;
	font-weight: 700;
	margin: 0 0 20px;
	width: 100%;
    color: #fff;
}
.test_link {
	border: 1px solid #fff;
	display: inline-block;
	font-size: .8em;
	font-weight: 700;
	letter-spacing: .1em;
	margin: 0 auto;
	padding: 10px 30px;
	text-transform: uppercase;
  color: #fff  !important;
}




.mt-120{
	margin-top:120px;
}

.text-white{
	color: #fff !important;
}

.box-01 .inner {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.footer-colored{
	background:rgb(39, 131, 144,0.5) !important;
}

#footer-bar a:not(.btn){
	color: #fff !important;
}



.color-bg{
	background: #e0b4aa;
}

.white-bg{
	background: #fff;
}


.dark-header{
	background-color: #333;
	border-bottom: #023666 thin solid;
}

.footer-bar-colored{
	background-color:rgb(0,0,0,.55) !important;;
}

.footer-bar-colored a:not(.btn){
	color:#023666 !important;
}

.golden-color, .footer-bar .active-nav i, .footer-bar .active-nav span{
	color:#023666 !important;
}
.mtb-0{
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

#drop_menu {
    background: #fff;
    border: thin solid #f1f1f1;
    box-shadow: 0 6px 15px #0000001f;
    padding: 20px 10px;
    position: absolute;
    width: 300px;
}

.drop-down-2 a h6{
	border-bottom: thin solid #f1f1f1;
	padding-bottom: 10px;
	padding-top: 10px;
}

.drop-down-2 a:last-child h6{
	border-bottom: none;
	padding-bottom: 0px;
}
.pl-10 {
    padding-left: 30px;
}
.header-bar a {
    position: relative;
}

.drop-down-1 a, .drop-down-2 a {
    display: contents;
    height: 30px;
    line-height: 30px;
    text-align: left!important;
}
.drop-down-1 a {
    color: #666;
    display: block;
    margin: 0;
    padding-left: 10px;
    width: 100%;
	margin-left: 0px !important;
}

.dark-theme-heading{
	color:#023666 !important;
}

.text-justify{
	text-align: justify;
}

.light-bg .row{
	background-color: #f2f2f2 !important;
}

.product-card-header{
	margin-top:-6px;
}


.img-sm{
	max-width: 180px;
    position: absolute;
    
    z-index: 0;
    height: auto;
    margin-top: -10px;
    margin-left: -60px;
}

.img-sm-1{
	max-width: 160px;
    position: absolute;
    
    z-index: 0;
    height: auto;
    margin-top: 230px;
    margin-left: -20px;
}



.text-offwhite{
	color:#ccc;
}
 .pt-50{
	padding-top:50px ;
 }

 .ptb-50{
	padding-top:50px ;
	padding-bottom:50px ;
 }


/*****Header CSS ********/

.dark-header{
	padding: 10px 0px;
	background-color: #fff;
    border-bottom: thin solid #f1f1f1;
}



.img-logo{
	max-width:85px;
	
}

.drop-menu-hidden {
    display: none!important;
}

.drop-menu-shown {
    display: block!important;
}

.header-bar * {
    align-self: auto!important;
}

.desktop_nav a{
	text-transform: uppercase;
	margin-left:60px ;
	font-size: 14px !important;
	text-align: center;
	font-weight: 700;
}
.desktop_nav span {
    display: inline-block;
    height: 85px;
    line-height: 80px;
}

.product-card{
	cursor: pointer;
	min-height: 500px;
}

.text-gold{
	color:#023666 !important
}

.mt-100{
	margin-top:130px;
}

@media screen and (min-width:1000px) and (max-width:1350px){
	.desktop_nav a{
		margin-left: 40px;
		font-size: 13px !important;
	}	
}  

@media screen and (min-width:771px) and (max-width:999px){
	.desktop_nav a{
		margin-left: 20px;
		font-size: 12px !important;
	}	
} 



@media screen and (max-width:770px){
	.cart-total tbody{
		text-align: right;
	}
	.easy-returns-block{
		margin-top:10px;
	  }
	.desktop_nav a{
		display:none !important;
	}
	.hide-phone{
		display:none !important;
	}
	.hide-desktop{
		display:inline !important;
	}

	.card-styled-dark{
		margin-top: 10px;
	}
	.img-logo{
		max-width: 55px;
	}

	.mobile-mt-20{
		margin-top: 20px;
	}
	.mobile-text-center{
		text-align: center;
	}
	.m-auto{
		margin:auto;
	}
}

@media screen and (min-width:771px){
	.hide-desktop{
		display:none !important;
	}
	.hide-phone{
		display:inline !important;
	}

	
}





/************Footer CSs *********/

.desktop_footer{
	padding: 50px 0px 10px 0px;
	
	background-color: #f1f1f1;
}


.test_title {
    color: #023666;
    display: block;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
}

.test_link {
    border: 1px solid #333;
    color: #333 !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .1em;
    margin: 0 auto 30px;
    padding: 8px 25px;
    text-transform: uppercase;
}

.footer-logo{
	width:60px;
}

.easy_returns{
	padding-top: 50px;
	padding-bottom: 50px;
	background-size: cover;
	background-position: center;
}

.easy-returns-block h4{
	 margin-top: 15px;
}

.footer-contact p{
	color: #fff;
}
.footer_links_social a{
	float: right !important;
	display:contents !important;
}

.footer_links_social a i{
	font-size: 22px;
	color: #023666;
	margin-right: 25px;
	margin-top: 15px;
}

.easy-returns-block svg{
	width: 50px;
}


.row-grid {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .row-grid   .column {
	flex: 33%;
	max-width: 33%;
	padding: 0 4px;
  }
  
  .row-grid   .column img {
	margin-top: 8px;
	vertical-align: middle;
	width: 100%;
  }

  .behind-scene{
	background-repeat: repeat;
  }

  .ptb-30{
	padding-top:30px;
	padding-bottom: 30px;
  }

  .wild-banner{
	max-width:750px;
  }

  .select-box{
	box-shadow: 0 5px 14px 0 rgb(0 0 0 / 10%) !important;
    background: transparent!important;
    border: none!important;
    color: #ccc!important;
  }



  .empty-cart{
	max-width: 190px;
	margin: auto;
  }

  .mtb-20{
	margin-bottom: 20px  !important;;
	margin-top: 20px  !important;;
  }

  .cart-table-header tr td p{
	margin-bottom: 0px;
	font-size: 16px;
	color: #ccc;
  }

  .table > thead :not(caption) > * > *{
border:none !important;
box-shadow: none;
  }

  .table > :not(:first-child){
	border:none;
  }

  .table > thead{
	vertical-align: bottom;
    border-bottom: thin solid #666;
  }

  .cart-img{
	max-width: 120px;
  }

.vertical-middle{
	vertical-align:middle;
} 

.qty-cart{
	max-width: 180px;
	margin:auto;
}

.subscribe-box-mobile{
	height: 48px;
	  padding-left: 5px;
	  width: calc(100% - 130px);
	  background: #fff;
	  border: thin solid #023666 !important;
	  margin-top: 10px;
	  border-right: none !important;
	  float:left;
	  
	  outline:none !important;
  }

  .ml--10{
	margin-left: -10px;
  }
  .pay-links-mobile{
	width:320px;
	margin:auto;
  }

  .pb-60{
	padding-bottom: 60px;
  }

  #search-form{
	width:100%;
	height:100vh;
	z-index: 99999999;
	position: absolute;
	top: 0px;
	left:0px;
	background-color: rgb(0,0,0,.85);
	display:none;
	text-align: center;
  }

  .search-input-box{
	margin: auto;
	max-width:250px;
	width:100%;
	height:50px;
	background-color: transparent;
	border:none ;
	outline:none !important;
	font-size: 18px !important;
	color:#ccc;
	border-bottom: #023666 thin solid;
  }

  .search-btn{
	position: absolute;
    top: 20px;
    right: 0px;
  }

  .topStrip {
    background: #023666;
    height: 15px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

  .pull-right{
	float: right;
  }

  #address .form-control, #account .form-control{
	background-color: #f1f1f1!important;
    border: thin solid #023666!important;
    color: #666;
    margin-top: 15px;
  }

  #address .form-control::placeholder,  #account .form-control::placeholder{
	color: #666;
  }

  .confrimBox{
	border: thin solid #023666;
	max-width: 400px;
	margin:auto;
	margin-top: 30px;
  }
  
  .box-header{
	background-color: #023666;
	padding:5px 10px;
  }


  .hr-prod{
	width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pt-10{
	padding-top:10px;
  }

 

  .mob-search-icon{
	float: right;
    margin-top: -35px;
  }
  
  .search-mobile-box{
	margin: auto;
  }

  .cursor-pointer{
	cursor: pointer;
  }

  #side-bar{
	position: fixed;
    left: -270px;
    height: 100vh;
    top: 0px;
    background: #fff;
    width: 270px;
	-webkit-animation: ease-in;
	animation: ease-in;
	transition-duration: 400ms;
  }

  #sidebar-bg{
	background-color: rgb(0,0,0,.75);
	position: fixed;
	top:0px;
	left:0px;
	width:100%;
	height:100vh;
	display:none;
  }

  .test_box_mob {
    padding: 0;
}

.test_box_mob .inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin: 10px;
    overflow: hidden;
	min-height: 330px;
}

.test_box_mob .inner .test_click {
    align-content: center;
    transition: all .3s ease;
}
.test_box_mob .inner .test_click {
    align-items: center;
    background-color: rgba(0,0,0,.1);
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    height: 330px;
    mix-blend-mode: normal;
    text-align: center;
    text-decoration: none;
}

.test_title_mob {
   font-size: 18px;
    font-weight: 700;
}
.test_title_mob {
    display: block;
    margin: 0 0 20px;
    width: 100%;
}

.test_link_mob {
    border: 1px solid #333;
    display: inline-block;
    font-size: .8em;
    letter-spacing: .1em;
    margin: 0 auto;
    padding: 10px 30px;
    text-transform: uppercase;
}
.test_link_mob, .test_title_mob {
   
    font-weight: 700;
}

.flex_this {
    display: inherit;
    flex-wrap: inherit;
    height: auto;
    margin: 0;
    padding: 10px;
    width: 100%;
}
  #close-sidebar{
	position: absolute;
	right:15px;
	top:10px;
  }
  @media screen and (max-width:1250px) {
	.search-desktop{
		display:none !important;
	}
	
  }

  .mobile_nav{
	padding: 15px;
    margin-top: 30px;
	overflow-y: auto;
	height: 95vh;
}

.bareware-img{
	padding:0px 10px;
}

.login-card{
	border:thin solid #023666;
	max-width:600px;
	margin:auto;
	margin-top:150px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.login-box input.form-control{
	padding-left: 6px;
    background: transparent !important;
    border: thin solid #023666 !important;
    outline: none;
	max-width:300px;
	margin:auto;
	margin-top:15px;
	color: black;
}



@media screen and (max-width:750px){
	.login-card{
		
		max-width:400px;
		margin-top:150px;
	}
}

.mt-20{
	margin-top: 20px !important;
}

.mt-25{
	margin-top: 25px !important;
}

.mt-15{
	margin-top: 15px !important;
}
input[type=text]{
	min-height: 35px;
}
.mb-10{
	margin-bottom: 10px !important;
}

.text-description{
color:	#333!important;
}

.account-card{
	border: thin solid #023666;
    max-width: 300px;
    margin: auto;
}

.ac-btn{
	margin-top:30px;
	border: thin solid #666;
	min-width: 155px;
}

.ac-btn img{
	max-width: 120px;
}

.upi-icon{
	width: 40px;
    filter: brightness(0) invert(1);
}

.w-50{
	width:50%;
}

.form-check{
	
	float: right;
	margin-top: -17.5px;
}


.form-check-input:checked{
	background-color: #023666;
	border-color:#023666;
	outline: none;
}

@media screen and (max-width:750px){
	.ac-btn img{
		max-width: 80px;
	}
	
}

.payment-btn{
	text-align: left;
	padding: 20px;
	border: thin solid #666;
}

.ac-container{
	max-width: 950px;
}

.back_btn{
	color: #ccc;
	text-decoration: underline;
	
}

.wishlist-btn{
	width:48%;
	max-width: 60px;
}
.fr{
	float: right;
}

.fl{
	float: left;
}

.mt-10{
	margin-top:10px !important;
}

.card-shadow{
	box-shadow: 0 5px 14px 0 rgb(0 0 0 / 10%);
}
.p-20{
	padding:20px;
}

.address-card{
	max-width: 330px;
	margin: auto;
}

.orderCard{
	max-width: 600px;
	border: thin solid #023666;
	margin: auto;
	padding:15px;
	margin-top: 20px;
	box-shadow: 0 5px 14px 0 rgb(0 0 0 / 30%);
}

.table-bordered tr td{
	color:#ccc;
	border: thin solid #023666;
	
}

.wrapped-up{
	word-wrap: break-word;
	line-break: anywhere;
}

.column a img{
	max-height: 270px;
}
.opacity-2{
	opacity: 0.2;
}

.cart-badge {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	background-color: #023666;
	color: #fff;
	position: absolute;
	top: -5px;
	right: -10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
    line-height: 22px;
    padding-top: 2px;
    padding-right: 2px;
}

.payment-active{
	border: thin solid #023666;
}

.coupon-input{
	width: 250px;
}

.test_box{
	background-color: #f1f1f1;
}

.fhome-box{
	padding: 5px;
	margin-top: -10px;
}

@media screen and (max-width:650px){
	.dark-header{
		padding: 0px 0px;
	}
	.header-container{
		margin-top: 0px !important;
	}
	.search-icon-mob{
		margin-top: -10px;
	}
}